<template>
    <b-modal :ref="modalRef" hide-footer title="État de vos comptes" @hidden="destroyModal" centered size="lg">
        <div class="text-center">
            <div v-if="user.utilisateur.discordId">
                <p>Votre compte de Clujiste est correctement relié à votre compte Discord</p>
                <b-button variant="warning" @click="deleteAssociation">Supprimer l'association</b-button>
            </div>
            <div v-else-if="discordCommand">
                <p>Envoyez cette commande sur le serveur Discord pour associer vos comptes</p>
                <p class="command">{{ discordCommand }}</p>
            </div>
            <div v-else>
                <p>Votre compte de Clujiste n'est pas relié à votre compte Discord</p>
                <b-button variant="info" @click="askForCommand">Lier mon compte</b-button>
            </div>
            <b-button @click="hideModal" class="mt-3">Fermer</b-button>
        </div>
    </b-modal>
</template>

<script>
import {mapState} from 'vuex';

import modalMixin       from '@/mixin/modalMixin';
import {apiPath}        from '@/util/http';
import alert            from '@/util/alert';
import {refreshSession} from '@/util/auth';

export default {
    name: "DiscordAssociationModal",
    mixins: [modalMixin],
    data: () => ({
        discordCommand: null,
        modalRef: 'userDetail'
    }),
    computed: {
        ...mapState({user: 'user'})
    },
    methods: {
        askForCommand() {
            alert.loading()
                .then(() => this.axios.get(apiPath('get_discord_association_command')))
                .then(response => this.discordCommand = response.data.command)
                .catch(() => this.$toaster.error('Impossible de récupérer la commande Discord'))
                .finally(alert.stopLoading);
        },
        deleteAssociation() {
            alert.loading()
                .then(() => this.axios.get(apiPath('delete_discord_association')))
                .then(refreshSession)
                .then(() =>{
                    this.$toaster.success('Association de comptes supprimée');
                    this.hideModal();
                })
                .catch(() => this.$toaster.error("Impossible de supprimer l'association de comptes"))
                .finally(alert.stopLoading);
        }
    }
}
</script>

<style scoped>
.command {
    color:black;
    font-weight: 700;
    padding: 0.5rem;
    border-radius: 10px;
    background-color: #e5e5e5;
}
</style>